<template>
  <section class="candidate-profile-external-review">
    <div
      v-if="!isEvaluated"
      class="candidate-profile-external-review__container"
    >
      <section class="candidate-profile-external-review__body">
        <h3 class="candidate-profile-external-review__title">
          {{ $t(`${langPath}.title`) }}
        </h3>

        <section class="candidate-profile-external-review__position">
          <div class="candidate-profile-external-review__evaluations">
            <h3 class="candidate-profile-external-review__subtitle">
              {{ $t(`${langPath}.profile`) }}
            </h3>
            <div
              v-for="profile in profileSubjects"
              :key="profile.id"
              class="candidate-profile-external-review__option"
            >
              <candidate-profile-external-review-option
                :title="feedbackSubjectOptionTitle(profile)"
                :id="profile.id"
                @input="addEvaluationToSubject"
              />
            </div>
          </div>

          <div class="candidate-profile-external-review__evaluations">
            <h3 class="candidate-profile-external-review__subtitle">
              {{ $t(`${langPath}.skill`) }}
            </h3>
            <div
              v-for="skill in skillSubjects"
              :key="skill.id"
              class="candidate-profile-external-review__option"
            >
              <candidate-profile-external-review-option
                :title="skill.name"
                :id="skill.id"
                @input="addEvaluationToSubject"
              />
            </div>
          </div>
        </section>

        <app-text-input
          v-validate="'required'"
          v-model="feedbackAuthor"
          :data-vv-as="$t(`${langPath}.authorLabel`)"
          :label="$t(`${langPath}.feedbackAuthor`)"
          name="feedbackAuthor"
          class="candidate-profile-external-review__input"
        />

        <app-text-area
          v-validate="'required'"
          v-model="feedbackComments"
          :data-vv-as="$t(`${langPath}.commentsLabel`)"
          :label="$t(`${langPath}.comments`)"
          name="feedbackComments"
          class="candidate-profile-external-review__input"
        />

        <div class="candidate-profile-external-review__footer">
          <ev-button
            full-width
            data-test="send-review-button"
            @click="sendExternalFeedback"
          >
            {{ $t(`${langPath}.sendFeedback`) }}
          </ev-button>
        </div>
      </section>
    </div>
    <div class="candidate-profile-external-review__received" v-else>
      <p class="candidate-profile-external-review__received-text">
        {{ $t(`${langPath}.feedbackDone`) }}
      </p>
      <icn-notification-card-success class="candidate-profile-external-review__received-icon" />
    </div>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import CandidateProfileExternalReviewOption from
  './CandidateProfileExternalReview/CandidateProfileExternalReviewOption'
import { SHARED_CANDIDATES, NOTIFICATION_CARDS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import { IcnNotificationCardSuccess } from '@revelotech/everest'
import { toCamelCase } from '@/helpers/case-style'

const { mapState, mapActions } = createNamespacedHelpers(SHARED_CANDIDATES)
const notificationHelper = createNamespacedHelpers(NOTIFICATION_CARDS)

export default {
  name: 'ExternalReview',

  components: {
    EvButton,
    CandidateProfileExternalReviewOption,
    IcnNotificationCardSuccess
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },

    token: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      subjectsEvaluations: [],
      feedbackAuthor: '',
      feedbackComments: ''
    }
  },

  computed: {
    ...mapState(['subjects', 'isEvaluated']),
    profileSubjects () {
      return this.subjects.filter((subject) => {
        return subject.model === 'Feedbacks::ProfileSubject'
      })
    },
    skillSubjects () {
      return this.subjects.filter((subject) => {
        return subject.model === 'Feedbacks::SkillSubject'
      })
    }
  },

  created () {
    this.getSharedCandidateSubjects(this.token)
    this.getEvaluationStatus(this.token)
  },

  methods: {
    ...mapActions([
      'getSharedCandidateSubjects',
      'createExternalFeedback',
      'getEvaluationStatus',
      'setEvaluationStatus'
    ]),

    ...notificationHelper.mapActions(['createNotificationCard']),

    feedbackSubjectOptionTitle (profile) {
      return this.$t(`${this.langPath}.${toCamelCase(profile.name)}`)
    },

    addEvaluationToSubject (event) {
      this.subjectsEvaluations = this.subjectsEvaluations.filter((subject) => {
        return subject.subjectId !== event.subjectId
      })

      this.subjectsEvaluations.push(event)
      this.subjectsEvaluations = this.subjectsEvaluations
        .map((subject) => subject.subjectId === event.subjectId ? event : subject)
    },

    sendExternalFeedback () {
      this.$validator.validate().then(isValid => {
        if (isValid) {
          const feedback = {
            token: this.token,
            evaluations: this.subjectsEvaluations,
            author: this.feedbackAuthor,
            comments: this.feedbackComments
          }

          this.createExternalFeedback(feedback).then(() => {
            this.createNotificationCard({
              title: this.$t(`${this.langPath}.successTitle`),
              message: this.$t(`${this.langPath}.successMessage`),
              modifier: 'success'
            })

            this.setEvaluationStatus(true)
          }).catch(() => {
            this.createNotificationCard({
              title: this.$t(`${this.langPath}.errorTitle`),
              message: this.$t(`${this.langPath}.errorMessage`),
              modifier: 'error'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-external-review {
  &__container {
    background-color: var(--bg-neutral-light-04);
    border: 1px solid var(--bg-neutral-light-04);
  }

  &__title {
    @extend %subtitle2;

    background-color: var(--bg-neutral-light-04);
    color: var(--tx-red-light-01);
    padding: 3*$base;
  }

  &__subtitle {
    @extend %body-text2;
  }

  &__position {
    background-color: var(--bg-neutral-blank);
    padding: 3*$base;
  }

  &__input {
    padding: 0 3*$base 2*$base;

    &--first {
      @include margin(top, 2);
    }
  }

  &__footer {
    padding: 0 3*$base 3*$base;
  }

  &__evaluations {
    &:last-child {
      @include margin(top, 3);
    }
  }

  &__received {
    align-items: center;
    border: solid 1px var(--b-blue);
    display: flex;
    padding: 3*$base;
  }

  &__received-icon {
    @include margin(left, 3);

    display: block;
    flex-shrink: 0;
    height: 6 * $base;
    width: 6 * $base;
  }

  &__received-text {
    @extend %body-text2;
  }
}
</style>
