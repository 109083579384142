<template>
  <section class="candidate-profile-external-review-option">
    <h3 class="candidate-profile-external-review-option__sub-title">
      {{ title }}
    </h3>

    <app-thumbs
      :name="numericId"
      @input="selectedEvaluate"
    />
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileExternalReviewOption',

  props: {
    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  computed: {
    numericId () {
      return parseInt(this.id)
    }
  },

  methods: {
    selectedEvaluate (value) {
      const payload = { subjectId: this.id, score: value }
      this.$emit('input', payload)
    }
  }
}
</script>

<style lang="scss">
.candidate-profile-external-review-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $base * 2;
  width: 80%;

  &__sub-title {
    @extend %body-text2;

    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
