<template>
  <div v-if="loading" class="shared-candidate-profile-loading">
    <app-loader data-test="loader" />
  </div>

  <div
    v-else
    class="shared-candidate-profile"
    data-test="main-content"
  >
    <app-grid>
      <app-grid-col :md="8" class="shared-candidate-profile__column">
        <div class="shared-candidate-profile__container">
          <div class="shared-candidate-profile__body">
            <template v-if="candidate.incompleteProfile">
              <candidate-profile-incomplete :candidate="candidate" />
              <candidate-profile-social :candidate="candidate" />
              <candidate-profile-cv :candidate="candidate" />
            </template>

            <template v-else>
              <candidate-profile-header
                :candidate="candidate"
                hide-share-button
                hide-wish-lists
              />
              <candidate-profile-about :candidate="candidate" />
              <candidate-profile-social :candidate="candidate" />
              <candidate-profile-jobs :candidate="candidate" />
              <candidate-profile-educations :candidate="candidate" />
              <candidate-profile-endorsements :candidate="candidate" />
              <candidate-profile-video :candidate="candidate" />
            </template>
          </div>
        </div>
      </app-grid-col>

      <app-grid-col :md="4">
        <aside
          data-test="sidebar-container"
          class="shared-candidate-profile__container shared-candidate-profile__container--sidebar"
        >
          <candidate-profile-external-review
            :candidate="candidate"
            :token="token"
          />
        </aside>
      </app-grid-col>
    </app-grid>
  </div>
</template>

<script>
import CandidateProfileAbout from '@/components/Candidates/CandidateProfileAbout'
import CandidateProfileEducations from '@/components/Candidates/CandidateProfileEducations'
import CandidateProfileEndorsements from '@/components/Candidates/CandidateProfileEndorsements'
import CandidateProfileHeader from '@/components/Candidates/CandidateProfileHeader'
import CandidateProfileIncomplete from '@/components/Candidates/CandidateProfileIncomplete'
import CandidateProfileJobs from '@/components/Candidates/CandidateProfileJobs'
import CandidateProfileCv from '@/components/Candidates/CandidateProfileCv'
import CandidateProfileSocial from '@/components/Candidates/CandidateProfileSocial'
import CandidateProfileVideo from '@/components/Candidates/CandidateProfileVideo'
import CandidateProfileExternalReview from
  '@/components/Candidates/CandidateProfileExternalReview'

import routerHelper from '@/helpers/router'
import gtmEvents from '@/helpers/constants/gtm-events'
import { CANDIDATES, CURRENT_USER, SHARED_CANDIDATES } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const candidatesHelper = createNamespacedHelpers(CANDIDATES)
const sharedCandidatesHelper = createNamespacedHelpers(SHARED_CANDIDATES)

export default {
  name: 'CandidatesSharedProfile',

  components: {
    CandidateProfileAbout,
    CandidateProfileEducations,
    CandidateProfileEndorsements,
    CandidateProfileHeader,
    CandidateProfileIncomplete,
    CandidateProfileJobs,
    CandidateProfileCv,
    CandidateProfileExternalReview,
    CandidateProfileSocial,
    CandidateProfileVideo
  },

  data () {
    return {
      candidate: {},
      loading: true
    }
  },

  computed: {
    ...candidatesHelper.mapGetters(['getCandidateById']),

    token () {
      return this.$route.params.sharedCandidateToken
    }
  },

  async created () {
    try {
      const { id } = await this.getSharedCandidate(this.token)
      this.candidate = this.getCandidateById(id)

      document.title = `${this.candidate.name} | Revelo`
    } catch (error) {
      routerHelper.redirectTo404()
    }

    try {
      await this.getCurrentUser()

      return this.$router.push({ name: 'CandidateProfile', params: { id: this.candidate.id } })
    } catch (_error) {}

    this.loading = false

    this.$gtmTrackEvent(gtmEvents.companyProfileView)
  },

  methods: {
    ...sharedCandidatesHelper.mapActions(['getSharedCandidate']),
    ...currentUserHelper.mapActions(['getCurrentUser'])
  }
}
</script>

<style lang="scss" scoped>
.shared-candidate-profile-loading {
  align-items: center;
  display: flex;
  height: 100vh;
}

.shared-candidate-profile {
  background: linear-gradient(to top, $white 80%, $gray100 50%);
  height: 100vh;
  margin-top: 66px;
  overflow: auto;
  padding-bottom: 40*$base;

  &__container {
    background-color: var(--bg-neutral-blank);
    border: 1px solid var(--bg-neutral-light-04);
    box-shadow: $shadow-8;
    height: 100%;
    padding: 4*$base;
    width: 100%;

    @include breakpoint(md) {
      @include margin(top, 12);

      padding: 6*$base;
    }

    @media print {
      border: 0;
    }

    &--sidebar {
      height: auto;
    }
  }

  &__body {
    display: block;
  }

  &__less-padding {
    padding: 6*$base 4*$base 0;

    @include breakpoint(md) {
      padding: 6*$base 3*$base 0;
    }
  }
}
</style>
